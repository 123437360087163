.selected-item {
  text-align: center;
}

.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  border: 1px solid #322b7c;
  padding: 0px 5px  !important;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  /* width: 100%; */
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  width: fit-content;
  /* min-width: 60px; */
  position: relative;
  text-align: center;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 500 !important;
  color: #524F67 !important;
  padding: 4px 10px;
  font-size: 10px;
  text-wrap: nowrap;
  transition: color 0.5s ease;
}

.segment.active label {
  color: #fff !important;
}

.controls::before {
  content: '';
  background: #322b7c;
  border-radius: 25px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 0px !important;
  bottom: 0px !important;
  left: -6px !important;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}
