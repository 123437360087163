.boxSummary {
  border: 1px #322b7c solid;
  border-radius: 15px;
  padding: 1rem;
  margin: 20px !important;
}
.innerBoxSummary {
  width: 100%;
}
.rightBorder {
  border-right: 1px solid #494761;
}

.totalBoxSummaryLabel {
  color: #747379 !important;
  font-size: 12.7px !important;
  font-weight: 400 !important;
}

.totalBoxSummaryValue {
  color: #000000 !important;
  font-weight: 600 !important;
  font-size: 32px !important;
}

.summaryTableBgHeading {
  background-color: #322b7c;
  color: #ffffff !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding: 0.5rem !important;
}

.summaryTableBtl {
  border-top-left-radius: 6px !important;
}

.summaryTableBtr {
  border-top-right-radius: 6px !important;
}

.loader {
  position: 'absolute';
  left: 0;
  right: 0;
  z-index: 1;
}
